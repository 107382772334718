import React from "react";
import styled from "styled-components";
import background from "../../assets/images/particle-bg-large.jpg";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${background}) center/cover;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #fff;
  max-width: 800px;
  margin: auto;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 0.5em;
  margin-top: 80px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

const Paragraph = styled.p`
  margin-bottom: 1em;
`;

const ContactInfo = styled.p`
  margin-bottom: 1em;
  a {
    color: #007bff;
    text-decoration: none;
  }
`;

const CfnPrivacyPolicy = () => {
  return (
    <PageWrapper>
      <Container>
        <Title>Privacy Policy for Colosseum Fight Night</Title>
        <p>Last updated: December 08, 2024</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          By using the Service, You agree to the collection and use of
          information in accordance with this Privacy Policy.
        </p>

        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p>
              <strong>Application</strong> refers to Colosseum Fight Night, the
              software program provided by the Company.
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to Falcon Delta, Hollého 103, 03601
              Martin.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: Slovakia
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Application.
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>

        <h2>Collecting and Using Your Data</h2>
        <h3>Types of Data Collected</h3>
        <h4>Installation ID</h4>
        <p>
          The only data collected by the Application is an anonymous
          Installation ID. This ID is used solely for enabling the anonymous
          voting feature for users’ favorite fighters without the need for an
          account. The Installation ID changes with every new installation of
          the Application, cannot be used to identify or track a user, and is
          never shared with third parties.
        </p>

        <h3>Use of Your Data</h3>
        <p>
          The Company may use the Installation ID for the following purposes:
        </p>
        <ul>
          <li>
            <p>
              <strong>To enable the voting feature:</strong> The Installation ID
              ensures that users can vote anonymously for their favorite
              fighters.
            </p>
          </li>
        </ul>

        <h3>Security of Your Data</h3>
        <p>
          The security of Your Data is important to Us. While We strive to use
          commercially acceptable means to protect Your data, We cannot
          guarantee its absolute security.
        </p>

        <h2>Children's Privacy</h2>
        <p>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
        </p>

        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          Us:
        </p>
        <ul>
          <li>By email: patrick@falcondelta.io</li>
        </ul>
      </Container>
    </PageWrapper>
  );
};

export default CfnPrivacyPolicy;
